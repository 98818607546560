/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-24 10:16:41
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-08-23 14:02:31
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signIn',
    name:"signIn",
    component: resolve => require(['../pages/SignIn/signIn'], resolve),
  },  
  //store
  {
    path: '/',
    name:"store",
    component: resolve => require(['../pages/HomePage/store'], resolve),
    children:[
      {
        path: '/',
        name:'serviceDataReport',
        component: resolve => require(['../pages/DataReport/serviceDataReport'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/serviceDataReport',
        name:'serviceDataReport',
        component: resolve => require(['../pages/DataReport/serviceDataReport'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/posterDataReport',
        name:'posterDataReport',
        component: resolve => require(['../pages/DataReport/posterDataReport'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/marketDataReport',
        name:'marketDataReport',
        component: resolve => require(['../pages/DataReport/marketDataReport'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/sceneDataReport',
        name:'sceneDataReport',
        component: resolve => require(['../pages/DataReport/sceneDataReport'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/functionUsageReport',
        name:'functionUsageReport',
        component: resolve => require(['../pages/DataReport/functionUsageReport'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/revenueDataReport',
        name:'revenueDataReport',
        component: resolve => require(['../pages/DataReport/revenueDataReport'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/panoramaReport',
        name:'panoramaReport',
        component: resolve => require(['../pages/DataReport/panoramaReport'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/brandData',
        name:'brandData',
        component: resolve => require(['../pages/DataStatistics/brandData'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      }, 
      {
        path: '/shopDataList',
        name:'shopDataList',
        component: resolve => require(['../pages/DataStatistics/shopDataList'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      }, 
      {
        path: '/enterpriseList',
        name:'enterpriseList',
        component: resolve => require(['../pages/DataStatistics/enterpriseList'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      }, 
      {
        path: '/accountData',
        name:'accountData',
        component: resolve => require(['../pages/DataStatistics/accountData'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/posterList',
        name:'posterList',
        component: resolve => require(['../pages/PosterManagement/posterList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/posterListCopy',
        name:'posterListCopy',
        component: resolve => require(['../pages/PosterManagement/posterListCopy'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/userFeedback',
        name:'userFeedback',
        component: resolve => require(['../pages/UserManagement/userFeedback'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      }, 
      {
        path: '/accountManagement',
        name:'accountManagement',
        component: resolve => require(['../pages/BusinessManagement/accountManagement'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      }, 
      {
        path: '/servicePerformance',
        name:'servicePerformance',
        component: resolve => require(['../pages/BusinessManagement/servicePerformance'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      }, 
      {
        path: '/operatingHistory',
        name:'operatingHistory',
        component: resolve => require(['../pages/BusinessManagement/operatingHistory'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      }, 
      {
        path: '/brandDataInfo',
        name:'brandDataInfo',
        component: resolve => require(['../pages/BrandDataInfo/brandDataInfo'], resolve),   
        meta:{ requireAuth:true,roleId:0,keepAlive: false }
      }, 
      {
        path: '/activityDataInfo',
        name:'activityDataInfo',
        component: resolve => require(['../pages/DataStatistics/activityDataInfo'], resolve),   
        meta:{ requireAuth:true,roleId:0,keepAlive: false }
      },
      {
        path: '/shopDataInfo',
        name:'shopDataInfo',
        component: resolve => require(['../pages/BrandDataInfo/shopDataInfo'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/accountDataInfo',
        name:'accountDataInfo',
        component: resolve => require(['../pages/BrandDataInfo/accountDataInfo'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/storeDataInfo',
        name:'storeDataInfo',
        component: resolve => require(['../pages/DataStatistics/storeDataInfo'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: true}
      },  
      {
        path: '/scenariosList',
        name:'scenariosList',
        component: resolve => require(['../pages/PanoramaManagement/scenariosList'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: true}
      }, 
      {
        path: '/sceneType',
        name:'sceneType',
        component: resolve => require(['../pages/PanoramaManagement/sceneType'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: true}
      }, 
      {
        path: '/pavingScheme',
        name:'pavingScheme',
        component: resolve => require(['../pages/PanoramaManagement/pavingScheme'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: true}
      },
      {
        path: '/housingTypeManagement',
        name:'housingTypeManagement',
        component: resolve => require(['../pages/PanoramaManagement/housingTypeManagement'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: true}
      },
      {
        path: '/invoiceList',
        name:'invoiceList',
        component: resolve => require(['../pages/InvoiceManagement/invoiceList'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: true}
      }, 
      {
        path: '/videoTutorialList',
        name:'videoTutorialList',
        component: resolve => require(['../pages/VideoTutorialManagement/videoTutorialList'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: true}
      }, 
      {
        path: '/LedgerUserList',
        name:'LedgerUserList',
        component: resolve => require(['../pages/DivideLedgerManagement/LedgerUserList'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: true}
      }, 
      {
        path: '/LedgerEpList',
        name:'LedgerEpList',
        component: resolve => require(['../pages/DivideLedgerManagement/LedgerEpList'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: true}
      }, 
      {
        path: '/LedgerOrderList',
        name:'LedgerOrderList',
        component: resolve => require(['../pages/DivideLedgerManagement/LedgerOrderList'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: true}
      }, 
      {
        path: '/LedgerOrderSplitList',
        name:'LedgerOrderSplitList',
        component: resolve => require(['../pages/DivideLedgerManagement/LedgerOrderSplitList'], resolve),   
        meta:{ requireAuth:true,roleId:0 ,keepAlive: true}
      }, 
    ]    
  },
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/signIn')) {
      window.localStorage.removeItem('token')
      next()
  } else {
      let user = window.localStorage.getItem('token');
      if (!user && to.path !== '/signIn') {
          next({
              path: '/signIn'
          })
      } else {
          next()
      }
  }
});

export default router
