/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-28 16:27:09
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-29 13:54:21
 */
var _DEVELOPMENT_MODE = false; //@devrain ==1 本地测试服务器 ==2 远程测试服务器 ==false 正式服务器

let apiServer = "https://zdt.taocitong.top";
let CServer = "https://c.taocitong.top";

if(_DEVELOPMENT_MODE==1){ //本地测试服务器
  apiServer = "http://192.168.0.169:80";
  CServer = "https://testc.taocitong.top";
}

if(_DEVELOPMENT_MODE==3){ //本地测试服务器
  apiServer = "http://192.168.0.236:8081";
  CServer = "https://testc.taocitong.top";
}

if(_DEVELOPMENT_MODE==2){ //远程测试服务器
  apiServer = "https://testzdt.taocitong.top";
  CServer = "https://testc.taocitong.top";
}

import axios from 'axios'
import {Message} from 'element-ui'
let connect = function(url, data, callback) {
        let token = localStorage.getItem("token");
        var authorization = 'Bearer ' + token
        //如果地址参数为全地址，直接使用全地址，不自动添加前缀。当存在不同服务器发起请求时可以按此依据直接调用，无需更改配置
        if(url.indexOf("http")==-1){
          url = apiServer+url;
        }
        axios({
            method: "post",
            url: url,
            withCredentials:false,
            data: data,
            transformRequest: [function (data) {
              var ret = ''
              for (var it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
              }
              return ret
            }],
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': authorization
            }
        }).then(function(res){
            if(res.data.code>0||res.data.isSuccess==1){
              callback&&callback(res.data);
            }
            else if(res.data.code<=0){
              Message(res.data.msg);
              callback&&callback(res.data);
              if(res.data.code<0){
                localStorage.clear()
                location.href="/";
              }
            }
        }).catch(function(err){
          console.log(err);
          // Message("当前网络异常");
        });
    }
export default{
  apiServer,
  CServer,
  connect
}
