/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-28 16:27:09
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-08-25 10:26:09
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-24 10:16:41
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-13 14:59:58
 */
var storeMenu = [
    {
        icon:"el-icon-s-home",
        index:"DataReport",
        sbuMenu:true,
        title:"控制台",
        type:0,
        subs:[
            {
                icon:"",
                index:"functionUsageReport",
                sbuMenu:true,
                subs:null,
                title:"功能使用报表",
                type:0,
            },
            {
                icon:"",
                index:"serviceDataReport",
                sbuMenu:true,
                subs:null,
                title:"客服数据报表",
                type:0,
            },
            {
                icon:"",
                index:"posterDataReport",
                sbuMenu:true,
                subs:null,
                title:"海报数据报表",
                type:1,
            },
            {
                icon:"",
                index:"marketDataReport",
                sbuMenu:true,
                subs:null,
                title:"市场数据报表",
                type:1,
            },
            {
                icon:"",
                index:"sceneDataReport",
                sbuMenu:true,
                subs:null,
                title:"全景数据报表",
                type:1,
            },
            {
                icon:"",
                index:"revenueDataReport",
                sbuMenu:false,
                subs:null,
                title:"营收数据报表",
                type:1,
            },{
                icon:"",
                index:"panoramaReport",
                sbuMenu:true,
                subs:null,
                title:"3D全景使用报表",
                type:1,
            },
        ],
    },
    {
        icon:"el-icon-s-marketing",
        index:"DataStatistics",
        sbuMenu:true,
        title:"数据管理",
        type:1,   
        subs:[
            {
                icon:"",
                index:"enterpriseList",
                sbuMenu:true,
                subs:null,
                title:"企业数据",
                type:0,
            },
            {
                icon: "",
                index:"brandData",
                sbuMenu:true,
                subs:null,
                title:"品牌数据",
                type:0,    
            },
            {
                icon:"",
                index:"shopDataList",
                sbuMenu:true,
                subs:null,
                title:"门店数据",
                type:0,    
            },
            {
                icon:"",
                index:"accountData",
                sbuMenu:true,
                subs:null,
                title:"账号数据",
                type:0,    
            }
        ],
    },
    {
        icon:"el-icon-question",
        index:"UserManagement",
        sbuMenu:true,
        title:"反馈管理",
        type:1,  
        subs:[
            {
                icon:"",
                index:"userFeedback",
                sbuMenu:true,
                subs:null,
                title:"反馈列表",
                type:0,    
            }          
        ],
    },
    {
        icon:"el-icon-tickets",
        index:"InvoiceManagement",
        sbuMenu:true,
        title:"发票管理",
        type:1,  
        subs:[
            {
                icon:"",
                index:"invoiceList",
                sbuMenu:true,
                subs:null,
                title:"发票列表",
                type:0,    
            }          
        ],
    },
    {
        icon:"el-icon-tickets",
        index:"DivideLedgerManagement",
        sbuMenu:false,
        title:"分账管理",
        type:1,  
        subs:[
            {
                icon:"",
                index:"LedgerUserList",
                sbuMenu:true,
                subs:null,
                title:"推广人员列表",
                type:0,    
            }
            ,{
                icon:"",
                index:"LedgerOrderList",
                sbuMenu:true,
                subs:null,
                title:"分账订单列表",
                type:0,    
            }             
        ],
    },
    {
        icon:"el-icon-video-camera-solid",
        index:"PanoramaManagement",
        sbuMenu:true,
        title:"全景管理",
        type:0,
        subs:[
            {
                icon:"",
                index:"scenariosList",
                sbuMenu:true,
                subs:null,
                title:"全景列表",
                type:0,
            },
            {
                icon:"",
                index:"sceneType",
                sbuMenu:true,
                subs:null,
                title:"全景类型",
                type:0,
            },
            {
                icon:"",
                index:"pavingScheme",
                sbuMenu:true,
                subs:null,
                title:"铺贴方案",
                type:0,
            },
            {
                icon:"",
                index:"housingTypeManagement",
                sbuMenu:true,
                subs:null,
                title:"小区户型管理",
                type:0,
            }
        ],
    },
    {
        icon:"el-icon-picture",
        index:"PosterManagement",
        sbuMenu:true,
        title:"海报管理",
        type:0,
        subs:[
            {
                icon:"",
                index:"posterList",
                sbuMenu:true,
                subs:null,
                title:"海报列表",
                type:0,
            },{
                icon:"",
                index:"posterListCopy",
                sbuMenu:true,
                subs:null,
                title:"海报文案",
                type:0,
            }
        ],
    },
    {
        icon:"el-icon-video-camera",
        index:"VideoTutorialManagement",
        sbuMenu:true,
        title:"视频管理",
        type:0,
        subs:[
            {
                icon:"",
                index:"videoTutorialList",
                sbuMenu:true,
                subs:null,
                title:"视频列表",
                type:0,
            }
        ],
    },
    {
        icon:"el-icon-s-tools",
        index:"BusinessManagement",
        sbuMenu:true,
        title:"系统管理",
        type:0,
        subs:[
            {
                icon:"iconfont icon-xiaoshou",
                index:"accountManagement",
                sbuMenu:true,
                subs:null,
                title:"账号管理",
                type:0,
            },
            {
                icon:"iconfont icon-xiaoshou",
                index:"servicePerformance",
                sbuMenu:true,
                subs:null,
                title:"客服绩效",
                type:0,
            },
            {
                icon:"iconfont icon-xiaoshou",
                index:"operatingHistory",
                sbuMenu:true,
                subs:null,
                title:"操作历史",
                type:0,
            }
        ]
    },
]

var menuJson = {
    storeMenu
}
export default menuJson